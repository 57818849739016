import {
  AddressBlockFragment,
  BigPlugFragment,
  BulletListFragment,
  CategoryGridFragment,
  ColorPlugsFragment,
  ContactCardFragment,
  ContactInfoBlockFragment,
  DividedTitleAndTextFragment,
  DividerBlockFragment,
  DotDigitalFormFragment,
  GreyInfoBoxFragment,
  HeroFragment,
  ImageBlockFragment,
  ImageSliderFragment,
  OfficesBlockFragment,
  PagePlugsFragment,
  ParentLinkBlockFragment,
  PersonellListFragment,
  PlainHeroBlockFragment,
  QuoteFragment,
  RelatedProductsBlockFragment,
  SimpleTextAndCtaFragment,
  TextBlockFragment,
  TitleAndTextBlockFragment,
  TwoImageBlockFragment,
  VideoFragment,
  VisibleSeoBlockFragment,
} from '../dato';

export function isAddressBlock(item: any): item is AddressBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'AddressBlockRecord';
}
export function isBigPlug(item: any): item is BigPlugFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'BigPlugRecord';
}

export function isCategoryGrid(item: any): item is CategoryGridFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'CategoryGridRecord';
}

export function isColorPlugs(item: any): item is ColorPlugsFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ColorPlugsBlockRecord';
}

export function isContactInfoBlock(
  item: any,
): item is ContactInfoBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ContactInfoBlockRecord';
}

export function isDividedTitleAndText(
  item: any,
): item is DividedTitleAndTextFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'DividedTitleAndTextRecord';
}

export function isGreyInfoBox(item: any): item is GreyInfoBoxFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'GreyInfoBoxRecord';
}

export function isHero(item: any): item is HeroFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'HeroRecord';
}

export function isPlainHeroBlock(item: any): item is PlainHeroBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'PlainHeroBlockRecord';
}
export function isOfficesBlock(item: any): item is OfficesBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'OfficesBlockRecord';
}
export function isPagePlugs(item: any): item is PagePlugsFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'PagePlugsBlockRecord';
}
export function isPersonellList(item: any): item is PersonellListFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'PersonellListRecord';
}

export function isSimpleTextAndCta(
  item: any,
): item is SimpleTextAndCtaFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'SimpleTextAndCtaRecord';
}

export function isTitleAndTextBlock(
  item: any,
): item is TitleAndTextBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'TitleAndTextBlockRecord';
}

export function isTextBlock(item: any): item is TextBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'TextBlockRecord';
}

export function isTwoImageBlock(item: any): item is TwoImageBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'TwoImageBlockRecord';
}

export function isVideo(item: any): item is VideoFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'VideoRecord';
}
export function isVisibleSeoBlock(item: any): item is VisibleSeoBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'VisibleSeoBlockRecord';
}

export function isBulletList(item: any): item is BulletListFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'BulletListRecord';
}

export function isContactCard(item: any): item is ContactCardFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ContactCardRecord';
}

export function isDotDigitalForm(item: any): item is DotDigitalFormFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'DotDigitalFormRecord';
}

export function isImageBlock(item: any): item is ImageBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ImageRecord';
}

export function isImageSlider(item: any): item is ImageSliderFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ImageSliderRecord';
}

export function isQuote(item: any): item is QuoteFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'QuoteRecord';
}

export function isRelatedProductsBlock(
  item: any,
): item is RelatedProductsBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'RelatedProductsBlockRecord';
}
export function isDividerBlock(item: any): item is DividerBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'DividerBlockRecord';
}

export function isParentLinkBlock(item: any): item is ParentLinkBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ParentLinkBlockRecord';
}
