import dynamic from 'next/dynamic';
import Image from 'next/image';

import { useMarketPicker } from '@hultafors/shared/context/hooks';

import { useGlobal } from '@hultafors/wibe/hooks';

import styles from './footer.module.scss';

const FooterStyled = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Footer),
);

const FooterOld = dynamic(() =>
  import('../footer-old/footer-old').then((mod) => mod.FooterOld),
);

interface FooterMenuLink {
  title?: string;
  url?: string;
}
interface FooterMenuItem {
  heading?: string;
  links?: FooterMenuLink[];
  includeCookieToggle?: boolean;
}

export const Footer = () => {
  const { footer, global } = useGlobal();
  const { toggle, activeMarket } = useMarketPicker();

  if (!footer.useNewFooter) {
    return <FooterOld />;
  }

  const Logo = () => {
    if (footer?.footerLogo?.url) {
      return (
        <div className={styles['logo']}>
          <Image src={footer?.footerLogo?.url ?? ''} alt="W.Steps" fill />
        </div>
      );
    }

    return null;
  };

  return (
    <FooterStyled
      activeMarket={activeMarket}
      brandLinks={footer?.hultaforsBrands ?? []}
      bottomIntro={footer?.bottomIntro ?? ''}
      introText={footer.introText ?? ''}
      items={(footer?.menuItems as FooterMenuItem[]) ?? []}
      logoAriaLabel="W.Steps"
      logoIcon={<Logo />}
      moreInfoText={footer?.moreInfoText ?? ''}
      selectCountryLabel={global?.changeLanguage ?? ''}
      socialMediaLabel={footer?.socialMediaLabel ?? ''}
      socialMediaMenu={footer?.socialMedia ?? []}
      toggleMarketPicker={toggle}
      cookieSettingsLabel={footer?.cookieSettings ?? ''}
    />
  );
};
